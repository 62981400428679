<template>
  <div>
    <a-button type="white" class="button-item" icon="import" @click="openModal">
      批量导入
    </a-button>
    <a-modal :visible="modalVisible" title="批量导入" @cancel="closeModal">
      <span>制造原厂：</span>
      <a-select
        v-model="factoryId"
        class="select"
        show-search
        allow-clear
        :filter-option="filterOption"
      >
        <a-select-option v-for="item in factoryList" :key="item.factoryId">
          {{ item.factoryName }}
        </a-select-option>
      </a-select>
      <div slot="footer">
        <a-button @click="closeModal">取消</a-button>
        <a-button
          v-if="!factoryId"
          type="primary"
          class="button-item"
          icon="import"
          @click="$message.error('请选择制造原厂')"
        >
          批量导入
        </a-button>
        <a-upload
          v-else
          name="file"
          accept=".xlsx,.xls"
          :multiple="false"
          :show-upload-list="false"
          :action="importDataUrl"
          :headers="headers"
          :data="setData"
          @change="importProductsChange"
        >
          <a-button
            type="primary"
            class="button-item upload-button"
            :loading="isLoading"
            :disabled="isLoading"
            icon="import"
          >
            批量导入
          </a-button>
        </a-upload>
        <a-button
          type="danger"
          shape="circle"
          icon="exception"
          v-if="isError"
          @click="showError"
        />
      </div>
    </a-modal>

    <!--  错误提示弹窗  -->
    <a-modal v-model="errorVisible" title="提示" :footer="null">
      <a-table
        :columns="errorColumns"
        :data-source="errorData"
        :pagination="false"
        :rowKey="(record, index) => index"
      />
    </a-modal>
  </div>
</template>
<script>
import { baseURL } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";

const errorColumns = [
  {
    title: "行数",
    dataIndex: "lineNum",
    width: "20%"
  },
  {
    title: "错误信息",
    dataIndex: "errMsg",
    width: "80%"
  }
];

export default {
  props: {
    factoryList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modalVisible: false,
      isLoading: false,
      importDataUrl: `${baseURL}/products/import`,
      headers: buildHeaders(),
      factoryId: "",
      isError: false,
      errorData: [],
      errorVisible: false,
      errorColumns
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    importProductsChange(info) {
      this.isLoading = true;
      if (info.file.status === "done") {
        if (info.file.response.code === "SUCCESS") {
          this.$message.success("导入成功");
          this.errorData = [];
          this.isError = false;
          this.closeModal();
          this.$emit("reset");
        } else {
          this.$message.error(info.file.response.errorMsg);
          this.isError = true;
          this.errorVisible = true;
          this.errorData = info.file.response.data || [];
          console.log(this.isError);
        }
        this.isLoading = false;
      } else if (info.file.status === "error") {
        this.$message.error("导入失败");
        this.isLoading = false;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    setData() {
      return { factoryId: this.factoryId };
    },
    // 展示错误
    showError() {
      this.errorVisible = true;
    }
  }
};
</script>

<style scoped>
.select {
  width: 300px;
}
.upload-button {
  margin-left: 8px;
}
</style>
