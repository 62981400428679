<template>
  <div class="tag-container">
    <div class="tag-list">
      <div class="tags-container">
        <div v-for="(tag, index) in list" :key="index" class="tag-item">
          <a-tooltip
            v-if="tag.label && tag.label.length > 8"
            :key="tag.label"
            :title="tag.label"
          >
            <a-tag
              :key="tag.label"
              class="tags"
              :closable="show"
              @close="handleClose($event, tag.label)"
            >
              {{ `${tag.label.slice(0, 8)}...` }}
            </a-tag>
          </a-tooltip>
          <a-tag
            v-else
            :key="tag.label"
            :closable="show"
            class="tags"
            @close="handleClose($event, tag.label)"
          >
            {{ tag.label }}
          </a-tag>
        </div>
      </div>
    </div>
    <div v-if="show" class="add-tag">
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :style="{ width: '78px' }"
        :value="inputValue"
        @change="handleInputChange"
        @keyup.enter="handleInputConfirm"
        @blur="handleInputConfirm"
      />
      <a-tag v-else class="add-tag" @click="addList">
        <a-icon type="plus" />
        {{ tagName }}
      </a-tag>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 内容
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    tagName: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputVisible: false,
      inputValue: ""
    };
  },
  methods: {
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    handleInputConfirm() {
      const value = { label: this.inputValue, value: this.inputValue };
      if (this.inputValue && this.list.indexOf(value) === -1) {
        const params = {
          value: value,
          tagName: this.tagName
        };
        this.$emit("addTag", params);
      }
      Object.assign(this, {
        inputVisible: false,
        inputValue: ""
      });
    },
    addList() {
      this.inputVisible = true;
    },
    handleClose(e, removedTag) {
      e.preventDefault();
      const value = { label: removedTag, value: removedTag };
      const param = {
        value: value,
        tagName: this.tagName
      };
      this.$emit("removeTag", param);
    }
  }
};
</script>

<style scoped>
.tags-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}
.tag-item {
  margin-bottom: 10px;
}
.add-tag .ant-tag {
  background-color: #4368f6;
  color: white;
}
/deep/.ant-tag {
  line-height: 30px;
}
.tag-container {
  display: flex;
}
/deep/ .ant-input-sm {
  height: 30px;
}
</style>
